"use client";

import React from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Home as HomeIcon, Search as SearchIcon } from "@mui/icons-material";
import Link from "next/link";
import Image from "next/image";

export default function NotFound() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage:
          "url(https://source.unsplash.com/random/1920x1080/?maze,lost)",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container maxWidth="md">
        <Paper
          elevation={6}
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            backdropFilter: "blur(10px)",
          }}
        >
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
            sx={{
              fontSize: isMobile ? "4rem" : "8rem",
              fontWeight: "bold",
              color: "primary.main",
              textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
            }}
          >
            404
          </Typography>
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            align="center"
            paragraph
          >
            Oops! <br />
            No encontramos la página
          </Typography>
          <Typography
            variant="body1"
            align="center"
            color="text.secondary"
            paragraph
            sx={{ maxWidth: "600px" }}
          >
            Lo sentimos, pero la página que buscas parece haber desaparecido en
            el aire. ¡No te preocupes, incluso los mejores exploradores se
            pierden a veces!
          </Typography>
          <Box my={4}>
            <Image
              src="/notFound.svg"
              alt="Lost in space illustration"
              width={200}
              height={200}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: 2,
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<HomeIcon />}
              component={Link}
              href="/"
              // sx={{
              //   py: 1.5,
              //   px: 4,
              //   fontSize: "1.1rem",
              // }}
            >
              Ir al inicio
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}
